import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,

  Card,
  CardBody,

} from "reactstrap";




// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";


import {
  getChats as onGetChats,
} from "store/actions";

import {
  getClasses as onGetClasses,
  getCourseSchedulesMega as ongetCourseSchedulesMega,
  getProjectsNew as onGetProjectsNew,
  getUsers as onGetUsers,
  getAdvert as onGetAdvert,
} from "store/contacts/actions";
const Dashboard = props => {


  const dispatch = useDispatch();

  const { classes } = useSelector(state => ({
    classes: state.contacts.classes,
  }));

  const { messagesNew } = useSelector(state => ({
    messagesNew: state.chat.chats,
  }));

  const { workshopSchedules } = useSelector(state => ({
    workshopSchedules: state.contacts.workshopSession,
  }));

  const { projects } = useSelector(state => ({
    projects: state.contacts.projects,
  }));

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));
  const { advert } = useSelector(state => ({
    advert: state.contacts.advert,
  }));


  const reports = [
    { title: "Aktif İlanlar", iconClass: "bx-archive-in", description: advert.length },
    { title: "Aktif Kullanıcılar", iconClass: "bx-archive-in", description: users.length },

   
  ];




  useEffect(() => {
    if (advert && !advert.length) {
      dispatch(onGetAdvert());
    }
  }, [dispatch, users]);
  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (projects && !projects.length) {
      dispatch(onGetProjectsNew());
    }
  }, [dispatch, projects]);

  useEffect(() => {
    if (workshopSchedules && !workshopSchedules.length) {
      dispatch(ongetCourseSchedulesMega());
    }
  }, [dispatch, workshopSchedules]);


  useEffect(() => {
    if (classes && !classes.length) {
      dispatch(onGetClasses());
    }
  }, [dispatch, classes]);

  useEffect(() => {
    if (messagesNew && !messagesNew.length) {
      dispatch(onGetChats());
    }
  }, [dispatch, messagesNew]);



  //meta title
  document.title = "Anasayfa | Tekneport Admin Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                            {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

             
            </Col>
          </Row>

        

         
        </Container>
      </div>

   
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
