import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  const [getUserType, setUserType] = React.useState("Admin");
  const [getSeenPage, setSeenPage] = React.useState([]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();

    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser != null) {
      console.log("lsdnflk",authUser.Users_Type_ID)
      setUserType(authUser.Users_Type_ID)
    }

  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    getSearchAuth()

  }, []);

  const getSearchAuth = async () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));

    if (authUser.Users_Type_ID != 1) {
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersAuthSearch/select/${authUser.Users_Type_ID}`)
        .then((res) => {

          setSeenPage(res.data)
          console.log("lskdfncccklds", res.data)
          //setUserAuth(res.data)
        })
    }
  }
  return (
    getUserType == 1 ? <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Anasayfa")} </li>

            <li>
              <Link to="/" >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Anasayfa")}</span>
              </Link>
            </li>
            {/*
            <li className="menu-title">{props.t("Yönetim")}</li>

            <li>
              <Link to="/educationList" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Eğitim")}</span>
              </Link>
            </li>

            <li>
              <Link to="/educationSubList" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("Eğitim Alt")}</span>
              </Link>
            </li>


            <li>
              <Link to="/usersList" >
                <i className="bx bx-user-pin"></i>
                <span>{props.t("Kullanıcılar")}</span>
              </Link>
            </li>
           
            <li>
              <Link to="/productscategory" >
                <i className="bx bx-grid"></i>
                <span>{props.t("Ürün Kategorisi")}</span>
              </Link>
            </li>
            <li>
              <Link to="/products" >
                <i className="bx bx-hive"></i>
                <span>{props.t("Ürünler")}</span>
              </Link>
            </li>
             */}

         
            <li>
              <Link to="/sliders" >
                <i className="bx bx-image"></i>
                <span>{props.t("Slider")}</span>
              </Link>
            </li>
            <li>
              <Link to="/rezervasyonowner" >
                <i className="bx bx-plus"></i>
                <span>{props.t("Rezervasyon Yapılan Onay Bekleyen İstekler")}</span>
              </Link>
            </li>

            <li>
              <Link to="/odemebekleyenistekler" >
                <i className="bx bx-message-rounded-dots"></i>
                <span>{props.t("Ödeme Bekleyen İstekler")}</span>
              </Link>
            </li>
            <li>
              <Link to="/aktifistekler" >
                <i className="bx bx-check"></i>
                <span>{props.t("Aktif İstekler")}</span>
              </Link>
            </li>
            <li>
              <Link to="/pasifistekler" >
                <i className="bx bx-x"></i>
                <span>{props.t("Pasif İstekler")}</span>
              </Link>
            </li>
            <li>
              <Link to="/contact" >
                <i className="bx bx-mobile"></i>
                <span>{props.t("İletişim")}</span>
              </Link>
            </li>

            <li>
              <Link to="/about" >
                <i className="bx bx-info-circle"></i>
                <span>{props.t("Hakkımızda")}</span>
              </Link>
            </li>
            <li>
              <Link to="/users" >
                <i className="bx bx-user-pin"></i>
                <span>{props.t("Kullanıcılar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/finance" >
                <i className="bx bx-money"></i>
                <span>{props.t("Finans")}</span>
              </Link>
            </li>



            <li>
              <Link to="/SSS" >
                <i className="bx bx-question-mark"></i>
                <span>{props.t("SSS")}</span>
              </Link>
            </li>

          

            <li>
              <Link to="/available" >
                <i className="bx bxs-ship"></i>
                <span>{props.t("Uygun Limanlar")}</span>
              </Link>
            </li>


            <li>
              <Link to="/advert" >
                <i className="bx bx-check"></i>
                <span>{props.t("İlanlar")}</span>
              </Link>
            </li>


            <li>
              <Link to="/offer" >
                <i className="bx bx-restaurant"></i>
                <span>{props.t("Yemekler")}</span>
              </Link>
            </li>

            <li>
              <Link to="/productsCategory" >
                <i className="bx bx-restaurant"></i>
                <span>{props.t("Hizmetler")}</span>
              </Link>
            </li>


            <li>
              <Link to="/blogs" >
                <i className="bx bx-check-circle"></i>
                <span>{props.t("Tur Şartları")}</span>
              </Link>
            </li>

            <li>
              <Link to="/teklif" >
                <i className="bx bx-calendar-edit"></i>
                <span>{props.t("Teklifler")}</span>
              </Link>
            </li> 
          
          {/**   <li>
              <Link to="/services" >
                <i className="bx bx-grid"></i>
                <span>{props.t("Servisler")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/reservation" >
                <i className="bx bxs-offer"></i>
                <span>{props.t("Rezervasyon")}</span>
              </Link>
            </li> 
            
         
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment> :
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Anasayfa")} </li>

              <li>
                <Link to="/calendar" >
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Anasayfa")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Yönetim")}</li>

              {getSeenPage.map((item, key) => (
                <li key={key}>
                  <Link to={item.Authority.Authority_Link} >
                    <i className="bx bx-calendar"></i>
                    <span>{item.Authority.Authority_Text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
