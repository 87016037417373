import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_FINANCE,GET_ADVERT, DELETE_ADVERT,UPDATE_ADVERT,ADD_ADVERT, GET_AVAILABLE, DELETE_AVAILABLE,UPDATE_AVAILABLE,ADD_AVAILABLE,GET_OFFER, DELETE_OFFER,UPDATE_OFFER,ADD_OFFER,
  GET_SERVICES, DELETE_SERVICES,UPDATE_SERVICES,ADD_SERVICES,
  GET_TEKLIF, UPDATE_TEKLIF,ADD_TEKLIF,
  GET_REZERVASYONTEKNESAHIBI,GET_ODEMEBEKLEYENISTEKLERTEKNESAHIBI,GET_AKTIFISTEKLERTEKNESAHIBI,GET_PASIFISTEKLERTEKNESAHIBI,
  GET_BLOGS, GET_RESERVATION, DELETE_RESERVATION,UPDATE_RESERVATION,ADD_RESERVATION,GET_SLİDERS,GET_SSS,DELETE_SSS,ADD_SSS,UPDATE_SSS, DELETE_DYNAMICFORM, DELETE_PRODUCTCATEGORY, DELETE_DYNAMICFORMEXTRA, DELETE_COURSESCHEDULES,
  DELETE_GALLERY, DELETE_NEWS, GET_DYNAMICFORM_EXTRA_EXTRA, GET_DYNAMICFORM_EXTRA, UPDATE_DYNAMICFORM, GET_CLASSES, GET_CLASSATTENDANCE,
  UPDATE_LESSONS_TIME, UPDATE_CONTACT, UPDATE_ABOUT, GET_TEACHER_WORKSHOP, GET_SUBPROJECT, UPDATE_REFERANCES, ADD_REFERANCES, GET_REFERANCES, ADD_WORKSHOP2, ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN, UPDATE_GALLERY, GET_GALLERY, UPDATE_WORKSHOP2, UPDATE_MEGA, UPDATE_AUTH, GET_AUTHORITY, GET_CONTACT,GET_ABOUT,
  UPDATE_WORKSHOPSESSION, UPDATE_SUBDOMAIN, ADD_COURSE, ADD_MEGA, GET_MEGA, GET_NEWS, GET_PROJECTSNEW, GET_SLIDER, GET_DYNAMICFORM, GET_EVENT,
  GET_TeacherMega, GET_WorkshopSessionMega, GET_CourseSchedulesMega, GET_POPUP, GET_USERS, GET_USER_PROFILE,
  ADD_NEW_USER, ADD_USER,DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_PRODUCTCATEGORY, ADD_SLIDER,
  ADD_PROJECT, ADD_NEWS, UPDATE_NEWS, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2,
  ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER,DELETE_BLOG, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY,
  ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, GET_EDUCATION, GET_SUBEDUCATION, UPDATE_EDUCATION, ADD_EDUCATION,
  UPDATE_SUBEDUCATION, ADD_SUBEDUCATION, DELETE_SUBEDUCATION, GET_PRODUCTCATEGORY, GET_PRODUCT, ADD_BLOG, UPDATE_PRODUCTCATEGORY,UPDATE_BLOG,ADD_PRODUCT,UPDATE_PRODUCT,DELETE_PRODUCT
} from "./actionTypes"

import {
  addTeklifSuccess,
  updateTeklifSuccess,
  getTeklifSuccess,
  getOdemeBekleyenIsteklerTekneSahibiSuccess,
  getAktifIsteklerTekneSahibiSuccess,
  getPasifIsteklerTekneSahibiSuccess,
  getRezervasyonTekneSahibiSuccess,
  addOfferSuccess,
  updateOfferSuccess,
  getOfferSuccess,
  deleteOfferSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  addSliderSuccess,
  addAdvertSuccess,
  updateAdvertSuccess,
  getAdvertSuccess,
  deleteAdvertSuccess,
  addSSSSuccess,
  updateSSSSuccess,
  getSSSSuccess,
  getFinanceSuccess,
  deleteSSSSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
  getNewsSuccess,
  getMegaSuccess,
  getAboutSuccess,
  getTeacherSuccess,
  getDynamicSuccess,
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateContactSuccess,
  updateAboutSuccess,
  updateNewsSuccess,
  updateBlogSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,
  deleteProductCategorySuccess,
  deleteNewsSuccess,
  deleteSliderSuccess,
  deleteBlogSuccess,
  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  getEducationSuccess,
  getSubEducationSuccess,
  updateEducationSuccess,
  addEducationSuccess,
  updateSubEducationSuccess,
  addSubEducationSuccess,
  deleteSubEducationSuccess,
  getProductCategorySuccess,
  getProductSuccess,
  getSlidersSuccess,
  getUsersSuccess,
  getBlogsSuccess,
  addProductCategorySuccess,
  addBlogSuccess,
  addProductSuccess,
  deleteProductSuccess,
  updateProductSuccess,
  addReservationSuccess,
  deleteReservationSuccess,
  updateReservationSuccess,
  getReservationSuccess,
  addAvailableSuccess,
  deleteAvailableSuccess,
  updateAvailableSuccess,
  getAvailableSuccess,
  updateSuccessBlog,


  addServicesSuccess,
  deleteServicesSuccess,
  updateServicesSuccess,
  getServicesSuccess,
  updateProductCategorySuccess,

} from "./actions"

//Include Both Helper File with needed methods
import {
  addUser,
  updateTeklif,addTeklif ,getTeklif,getRezervasyonTekneSahibi,getOdemeBekleyenIsteklerTekneSahibi,getAktifIsteklerTekneSahibi,
  updateServices,deleteServices,addServices ,getServices,getPasifIsteklerTekneSahibi,
  updateAdvert,deleteAdvert,addAdvert ,getAdvert,getFinance,updateAvailable,deleteAvailable,addAvailable ,getAvailable,updateOffer,deleteOffer,addOffer ,getOffer,updateReservation,deleteReservation,addReservation ,getReservation, updateProduct,deleteProduct,addProduct ,updateProductCategory,updateAbout, getSSS,addSSS,updateSSS,deleteSSS,getBlogs, getSliders, getCategory, deleteSubEducation, deleteBlog, deleteProductCategory, addSubEducation,addBlog, updateSubEducation, addEducation, updateEducation, getEducationSubItem, getEducation, deleteDynamicFormExtraExtra, deleteDynamicForm, deleteDynamicFormExtra, deleteCourseSchedules, addMultipleGalleryForm, deleteGallery, addGallerry, addProductCategory, deleteReferances, deletePopup, deleteSlider, deleteNews, deleteMega, getDynamicFormExtraExtraMega, getDynamicFormExtraMega, addDynamicForm, updateDynamicForm, getClassesMega, getClassAttendance, updateLessonsTime, addLessonsTime, getTeacherWorkshop, getSubProject, updateReferances, addReferances, getReferances, addWorkshop2, addAppSetting, addNewSubdomain, updateGallery, getGallery, updateWorkShop2, getWorkshop2, updateMega, updateProject, updateSlider, updateAuth, getAuthority, updateContact, getContact, getAbout,updateBlog, updateTeacher, updateWorkshopSession, updateSubdomain, addCourseMega, updateCourseSchedules, updateNews, addMega, addNews, addProject, addSlider, addPopup, getMegaMega, getNews, getProjectsNew, getSlider, getDynamicFormMega, getEventMega, getTeacherMega, getWorkshopSessionMega, getPopup, getUsers, getUserProfile, addNewUser, updateUser, deleteUser, getWorkShop, getCourseSchedulesMega, getProducts1 } from "../../helpers/fakebackend_helper"

function* onDeleteAdvert({ payload: user }) {
  try {
    const response = yield call(deleteAdvert, user)
    yield put(deleteAdvertSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddAdvert({ payload: user }) {
  try {
    const response = yield call(addAdvert, user)

    yield put(addAdvertSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAdvert(item) {
  try {
    const response = yield call(getAdvert, item.users)
    yield put(getAdvertSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateAdvert({ payload: user }) {
  try {
    const response = yield call(updateAdvert, user)
    console.log("lkmsdlkf",response)
    yield put(updateAdvertSuccess(response.value[0]))
  } catch (error) {
    console.log("error",error)

    yield put(updateUserFail(error))
  }
}

function* onGetRezervasyonTekneSahibi(item) {
  try {
    const response = yield call(getRezervasyonTekneSahibi, item.payload)

    yield put(getRezervasyonTekneSahibiSuccess(response))

    
  } catch (error) {
    console.log("smklf",error)
    yield put(getUsersFail(error))
  }
}


function* onGetOdemeBekleyenIsteklerTekneSahibi(item) {
  try {
    const response = yield call(getOdemeBekleyenIsteklerTekneSahibi, item.payload)

    console.log("123213",item.payload)
    yield put(getOdemeBekleyenIsteklerTekneSahibiSuccess(response))

    
  } catch (error) {
    console.log("smklf",error)
    yield put(getUsersFail(error))
  }
}

function* onGetAktifIsteklerTekneSahibi(item) {
  try {
    const response = yield call(getAktifIsteklerTekneSahibi, item.payload)

    console.log("123213",item.payload)
    yield put(getAktifIsteklerTekneSahibiSuccess(response))

    
  } catch (error) {
    console.log("smklf",error)
    yield put(getUsersFail(error))
  }
}



function* onGetPasifIsteklerTekneSahibi(item) {
  try {
    const response = yield call(getPasifIsteklerTekneSahibi, item.payload)

    console.log("123213",item.payload)
    yield put(getPasifIsteklerTekneSahibiSuccess(response))

    
  } catch (error) {
    console.log("smklf",error)
    yield put(getUsersFail(error))
  }
}


function* onDeleteServices({ payload: user }) {
  try {
    const response = yield call(deleteServices, user)
    yield put(deleteServicesSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddServices({ payload: user }) {
  try {
    const response = yield call(addServices, user)

    yield put(addServicesSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetServices(item) {
  try {
    const response = yield call(getServices, item.users)
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateServices({ payload: user }) {
  try {
    const response = yield call(updateServices, user)
    yield put(updateServicesSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}







function* onAddTeklif({ payload: user }) {
  try {
    const response = yield call(addTeklif, user)

    yield put(addTeklifSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetTeklif(item) {
  try {
    const response = yield call(getTeklif, item.users)
    yield put(getTeklifSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateTeklif({ payload: user }) {
  try {
    const response = yield call(updateTeklif, user)
    yield put(updateTeklifSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}






function* onGetFinance(item) {
  try {
    const response = yield call(getFinance, item.users)
    yield put(getFinanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}



function* onDeleteSubEducation({ payload: user }) {
  try {
    const response = yield call(deleteSubEducation, user)
    yield put(deleteSubEducationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteAvailable({ payload: user }) {
  try {
    const response = yield call(deleteAvailable, user)
    yield put(deleteAvailableSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddAvailable({ payload: user }) {
  try {
    const response = yield call(addAvailable, user)

    yield put(addAvailableSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAvailable(item) {
  try {
    const response = yield call(getAvailable, item.users)
    yield put(getAvailableSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateAvailable({ payload: user }) {
  try {
    const response = yield call(updateAvailable, user)
    yield put(updateAvailableSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}







function* onDeleteOffer({ payload: user }) {
  try {
    const response = yield call(deleteOffer, user)
    yield put(deleteOfferSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddOffer({ payload: user }) {
  try {
    const response = yield call(addOffer, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetOffer(item) {
  try {
    const response = yield call(getOffer, item.users)
    yield put(getOfferSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateOffer({ payload: user }) {
  try {
    const response = yield call(updateOffer, user)
    yield put(updateOfferSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}





function* onDeleteReservation({ payload: user }) {
  try {
    const response = yield call(deleteReservation, user)
    yield put(deleteReservationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddReservation({ payload: user }) {
  try {
    const response = yield call(addReservation, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetReservation(item) {
  try {
    const response = yield call(getReservation, item.users)
    yield put(getReservationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateReservation({ payload: user }) {
  try {
    const response = yield call(updateReservation, user)
    yield put(updateReservationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onDeleteProduct({ payload: user }) {
  try {
    const response = yield call(deleteProduct, user)
    yield put(deleteProductSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddProduct({ payload: user }) {

  try {
    const response = yield call(addProduct, user)

    yield put(addProductSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateProduct({ payload: user }) {
  try {
    const response = yield call(updateProduct, user)
    yield put(updateProductSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteSss({ payload: user }) {
  try {
    const response = yield call(deleteSSS, user)
    yield put(deleteSSSSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSSS({ payload: user }) {

  try {
    const response = yield call(addSSS, user)

    yield put(addSSSSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateSSS({ payload: user }) {
  try {
    const response = yield call(updateSSS, user)
    yield put(updateSSSSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetSSS(item) {
  try {
    const response = yield call(getSSS, item.users)
    yield put(getSSSSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}




function* onDeleteBlog({ payload: user }) {
  try {
    const response = yield call(deleteBlog, user)
    yield put(deleteBlogSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSubEducation({ payload: user }) {

  try {
    const response = yield call(addSubEducation, user)

    yield put(addSubEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateSubEducation({ payload: user }) {
  try {
    const response = yield call(updateSubEducation, user)
    yield put(updateSubEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateEducation({ payload: user }) {
  try {
    const response = yield call(updateEducation, user)
    yield put(updateEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(updateContactSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateAbout({ payload: user }) {
  try {
    const response = yield call(updateAbout, user)
    yield put(updateAboutSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetEducationSubItem(item) {
  try {
    const response = yield call(getEducationSubItem, item.users)
    yield put(getSubEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetEducation(item) {
  try {
    const response = yield call(getEducation, item.users)
    yield put(getEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* ongetProductCategory(item) {
  try {
    const response = yield call(getCategory, item.users)
    console.log("response", response)
    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetProduct(item) {
  try {
    const response = yield call(getProducts1, item.users)
    yield put(getProductSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetSliders(item) {
  try {
    const response = yield call(getSliders, item.users)
    yield put(getSlidersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetBlogs(item) {
  try {
    const response = yield call(getBlogs, item.users)
    console.log("kbsdf", response)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetUsers(item) {
  try {
    const response = yield call(getUsers, item.users)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance() {
  try {
    const response = yield call(getClassAttendance)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetTeacherWorkshopMega() {
  try {
    const response = yield call(getTeacherWorkshop)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject(item.news))
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)
    yield put(updateLessonsTimeSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onAddEducation({ payload: user }) {

  try {
    const response = yield call(addEducation, user)

    yield put(addEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}





function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAbout() {
  try {
    const response = yield call(getAbout)
    yield put(getAboutSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateBlog({ payload: user }) {
  try {
    const response = yield call(updateBlog, user)
    yield put(updateSuccessBlog(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onUpdateProductCategory({ payload: user }) {
  try {
    const response = yield call(updateProductCategory, user)
    console.log("jlnsdfl", response)
    yield put(updateProductCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega() {
  try {
    const response = yield call(getTeacherMega)
    yield put(getTeacherSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    console.log("response123", response)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateSuccessCourseSchedules(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateNews({ payload: user }) {
  try {
    const response = yield call(updateNews, user)
    yield put(updateNewsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteUserSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}




function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    console.log("respocccc13", response.value[0])
    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddNews({ payload: user }) {

  try {
    const response = yield call(addNews, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddSlider({ payload: user }) {

  try {
    const response = yield call(addSlider, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    console.log("user123", user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductCategory, user)
    console.log("user123", user)
    yield put(deleteProductCategorySuccess(user,response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    const response = yield call(deleteNews, user)
    console.log("user123", user)
    yield put(deleteNewsSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    console.log("user123", user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123", user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123", user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddProductCategory({ payload: user }) {

  try {
    const response = yield call(addProductCategory, user)

    yield put(addProductCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}




function* onAddUser({ payload: user }) {

  try {
    const response = yield call(addUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddBlog({ payload: user }) {

  try {
    const response = yield call(addBlog, user)

    yield put(addBlogSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

    //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* contactsSaga() {


  yield takeEvery(GET_RESERVATION, onGetReservation)
  yield takeEvery(DELETE_RESERVATION, onDeleteReservation)

  yield takeEvery(ADD_RESERVATION, onAddReservation)

  yield takeEvery(UPDATE_RESERVATION, onUpdateReservation)




  yield takeEvery(GET_ADVERT, onGetAdvert)
  yield takeEvery(DELETE_ADVERT, onDeleteAdvert)

  yield takeEvery(ADD_ADVERT, onAddAdvert)

  yield takeEvery(UPDATE_ADVERT, onUpdateAdvert)




  yield takeEvery(GET_AVAILABLE, onGetAvailable)
  yield takeEvery(DELETE_AVAILABLE, onDeleteAvailable)

  yield takeEvery(ADD_AVAILABLE, onAddAvailable)

  yield takeEvery(UPDATE_AVAILABLE, onUpdateAvailable)


  yield takeEvery(DELETE_SUBEDUCATION, onDeleteSubEducation)


  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(ADD_PRODUCT, onAddProduct)

  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)

  

  yield takeEvery(DELETE_BLOG, onDeleteBlog)

  yield takeEvery(DELETE_SSS, onDeleteSss)
  yield takeEvery(ADD_SSS, onAddSSS)
  yield takeEvery(GET_SSS, onGetSSS)
  yield takeEvery(UPDATE_SSS, onUpdateSSS)


  yield takeEvery(DELETE_OFFER, onDeleteOffer)
  yield takeEvery(ADD_OFFER, onAddOffer)
  yield takeEvery(GET_OFFER, onGetOffer)
  yield takeEvery(UPDATE_OFFER, onUpdateOffer)


  yield takeEvery(DELETE_SERVICES, onDeleteServices)
  yield takeEvery(ADD_SERVICES, onAddServices)
  yield takeEvery(GET_SERVICES, onGetServices)
  yield takeEvery(UPDATE_SERVICES, onUpdateServices)


  yield takeEvery(ADD_TEKLIF, onAddTeklif)
  yield takeEvery(GET_TEKLIF, onGetTeklif)
  yield takeEvery(UPDATE_TEKLIF, onUpdateTeklif)

  yield takeEvery(ADD_SUBEDUCATION, onAddSubEducation)

  yield takeEvery(UPDATE_SUBEDUCATION, onUpdateSubEducation)

  yield takeEvery(ADD_EDUCATION, onAddEducation)

  yield takeEvery(UPDATE_EDUCATION, onUpdateEducation)

  yield takeEvery(GET_SUBEDUCATION, onGetEducationSubItem)

  yield takeEvery(GET_EDUCATION, onGetEducation)
  yield takeEvery(GET_PRODUCTCATEGORY, ongetProductCategory)

  yield takeEvery(GET_PRODUCT, onGetProduct)

  yield takeEvery(GET_SLİDERS, onGetSliders)
  yield takeEvery(GET_BLOGS, onGetBlogs)
  yield takeEvery(GET_FINANCE, onGetFinance)
  yield takeEvery(GET_USERS, onGetUsers)
  yield takeEvery(GET_REZERVASYONTEKNESAHIBI, onGetRezervasyonTekneSahibi)

  yield takeEvery(GET_PASIFISTEKLERTEKNESAHIBI, onGetPasifIsteklerTekneSahibi)
  yield takeEvery(GET_AKTIFISTEKLERTEKNESAHIBI, onGetAktifIsteklerTekneSahibi)
  yield takeEvery(GET_ODEMEBEKLEYENISTEKLERTEKNESAHIBI, onGetOdemeBekleyenIsteklerTekneSahibi)
  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)


  yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory)

  yield takeEvery(ADD_BLOG, onAddBlog)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)
  yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategory)

  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_BLOG, onUpdateBlog)
  yield takeEvery(UPDATE_PRODUCTCATEGORY, onUpdateProductCategory)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(UPDATE_ABOUT, onUpdateAbout)

  yield takeEvery(GET_CONTACT, fetchContact)
  yield takeEvery(GET_ABOUT, fetchAbout)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)




  yield takeEvery(ADD_COURSE, onAddCourse)

  yield takeEvery(UPDATE_NEWS, onUpdateNews)

  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)

  yield takeEvery(GET_SLIDER, fetchSlider)

  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(ADD_NEW_USER, onAddUser)

  
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
